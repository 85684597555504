import React from 'react'

import { injectIntl } from 'react-intl'
import Translation from '../../global/Translation'
import { getSpecialNames, getToken } from '../../../store/login/reducers'
import { getLanguage } from '../../../store/ui/reducers'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import ReactTable from 'react-table'
import ProductDisplayName from '../../global/ProductDisplayName'
import DisplayBrand from '../../catalog/rectangleResult/displays/DisplayBrand'
import DisplayThickness from '../../catalog/rectangleResult/displays/DisplayThickness'
import DisplayOutletIndicator from '../../catalog/rectangleResult/displays/DisplayOutletIndicator'

import DisplayDetail from '../../catalog/rectangleResult/displays/DisplayDetail'
import { getDiscountMaterials } from '../../../store/promotions/reducers'
import { requestMaterialsDiscounts } from '../../../store/promotions/actions'
import { typeMap } from '../../global/TypeMaps'
import Button from '../../global/Button'
import {cloneDeep} from "lodash"


const VALID_ROTATIONS = "CDFKRSX";
class ChooseDiscounts extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            selectedMaterials: {},
            search: '',
            discountMaterialsCloned: null,
            hasUnsavedChanges: {
                promotionDiscountsDirty: false
            }
        }
    }

    goToStepOne = e => {
        e.preventDefault()
        this.props.changeStep(this.props.step - 4)
    }

    handleSubmit = e => {
        if(e) e.preventDefault()

        //No more unsaved changes after send the form for step three
        this.setState({ hasUnsavedChanges: {
            ...this.state.hasUnsavedChanges,
            promotionDiscountsDirty: false
        }}, () => {
            this.props.requestDiscounts()
            //Update global state after form submit
            this.props.setUnsavedChangesStepFive(this.state.hasUnsavedChanges)
            //Make sure the change step is the last function
            
            //Only if event exists we use this change step. If not we use the one inside StepsBar component
            if (e) {
                this.props.changeStep(this.props.step + 1)
            }
        })
        
        // const selectedMaterials = [];
        // Object.entries(this.state.selectedMaterials).forEach(([productId, isSelected]) => {if (isSelected) selectedMaterials.push(productId)})
        // console.log('SEE', selectedMaterials)

        // this.props.sendMaterialsSelected(this.state.selectedMaterials, this.state.currentMaterialsData).then(() => this.props.changeStep(this.props.step + 1))
    }

    markMaterial = (productId, isChecked) => {
        if (!this.props.discountMaterials[productId]) {
            this.props.discountMaterials[productId] = {
                materialId: productId,
                acceptedDiscount: false
            }
            this.props.discountMaterials[productId].requestedDiscount = isChecked
        } else if(this.props.discountMaterials[productId]) {
            //This is necessary to be able to compare the initial state and the final state of the objects for unsaved changes.
            delete this.props.discountMaterials[productId]
        }
        this.forceUpdate()
        this.handleDiscountsUnsavedChanges()
    }
    canAskForDiscount = original => {
        if (original.outlet) return true
        if (VALID_ROTATIONS.includes(original.indRotation)) return true
        return false
    }
    getNameText = product => {
        const { specialNames } = this.props
        let LANG_TO_INDEX = {
            da: 'EN',
            de: 'DE',
            'en-US': 'Z2',
            en: 'EN',
            es: 'ES',
            fi: 'EN',
            'fr-CA': 'Z2',
            'en-CA': 'Z2',
            fr: 'FR',
            it: 'IT',
            nl: 'NL',
            no: 'EN',
            pt: 'PT',
            pl: 'EN',
            sv: 'SV',
            tr: 'TR'
        }
        let locale = LANG_TO_INDEX[this.props.intl.locale]

        let showName

        if (specialNames && specialNames[product.productId]) {
            showName = specialNames[product.productId]
        } else if (product.translations && product.translations[locale]) {
            showName = product.translations[locale].displayName.toLowerCase()
        } else {
            showName = product.displayName.toLowerCase()
        }
        return showName
    }

    /**
     * Handle the state value material discounts for unsaved changes.
     */
    handleDiscountsUnsavedChanges = () => {
        const stateDiscounts = this.state.discountMaterialsCloned
        const propsDiscounts = this.props.discountMaterials

        const stateDiscountsKeys = stateDiscounts && Object.keys(stateDiscounts)
        const propsDiscountsKeys = propsDiscounts && Object.keys(propsDiscounts)

        if ((propsDiscountsKeys && JSON.stringify(propsDiscountsKeys).trim()) !== (stateDiscountsKeys && JSON.stringify(stateDiscountsKeys).trim())) {
            this.setState({hasUnsavedChanges: {...this.state.hasUnsavedChanges, promotionDiscountsDirty: true}}, () => {
                //Update global state
                this.props.setUnsavedChangesStepFive(this.state.hasUnsavedChanges)
            })
        } else if ((propsDiscountsKeys && JSON.stringify(propsDiscountsKeys).trim()) === (stateDiscountsKeys && JSON.stringify(stateDiscountsKeys).trim())) {
            this.setState({hasUnsavedChanges: {...this.state.hasUnsavedChanges, promotionDiscountsDirty: false}}, () => {
                //Update global state
                this.props.setUnsavedChangesStepFive(this.state.hasUnsavedChanges)
            })
        }
    }

    componentDidMount() {
        this.props.handleReference(this)

        //Get the initial value of discount materials to have something to compare
        const discountMaterialsCloned = cloneDeep(this.props.discountMaterials)
        this.setState({ discountMaterialsCloned: discountMaterialsCloned })
    }

    render() {
        const headerStep = (
            <div className="row">
                <div className="col-12 col-lg-10">
                    <h3 className="header-step-promotion" style={{ fontWeight: '400' }}>
                        <span style={{ fontWeight: '300' }}>
                            <Translation id="step" defaultMessage={'Step'} /> {this.props.step}
                        </span>{' '}
                        <Translation id="promotion_line_request_discount" defaultMessage="Request discounts" />
                    </h3>
                </div>
            </div>
        )

        const formHandleSubmit = <form id="form_current_step" onSubmit={e => this.handleSubmit(e)} />

        let selectedMaterialsFiltered = this.props.materialsSelectedData ? this.props.materialsSelectedData : []
        if (selectedMaterialsFiltered.length > 0 && this.state.search) {
            selectedMaterialsFiltered = selectedMaterialsFiltered.filter(product => {
                let indRotationLabel
                if (product.translations) {
                    let lang = this.props.language.toUpperCase()
                    if (!(lang in product.translations)) lang = 'Z2'
                    if (lang in product.translations) {
                        indRotationLabel = product.translations[lang].indRotation && product.translations[lang].indRotation.toLowerCase()
                    }
                }

                return (
                    this.getNameText(product)
                        .toLowerCase()
                        .includes(this.state.search.toLowerCase()) ||
                    product.productId.toLowerCase().includes(this.state.search.toLowerCase()) ||
                    product.brand.toLowerCase().includes(this.state.search.toLowerCase()) ||
                    this.props.intl
                        .formatMessage({ id: product.finish })
                        .toLowerCase()
                        .includes(this.state.search.toLowerCase()) ||
                    this.props.intl
                        .formatMessage({ id: product.type })
                        .toLowerCase()
                        .includes(this.state.search.toLowerCase()) ||
                    this.props.intl
                        .formatMessage({ id: product.subtypeName })
                        .toLowerCase()
                        .includes(this.state.search.toLowerCase()) ||
                    product.thickness.toLowerCase().includes(this.state.search.toLowerCase()) ||
                    (indRotationLabel && indRotationLabel.includes(this.state.search.toLowerCase()))
                )
            })
        }

        return (
            <div>
                {this.props.canViewDiscountRequest ? (
                    <React.Fragment>
                        {headerStep}
                        <div className="container">
                            <div className="row">
                                <p>
                                    <Translation id="select_the_discounts_to_apply_your_campaign" defaultMessage="Select the discounts to apply your campaign" />
                                </p>
                                <div className="col-12">
                                    <div className="search-bar">
                                        <input
                                            type="text"
                                            data-qa="query"
                                            className="search-box form-control"
                                            placeholder={this.props.intl.formatMessage({ id: 'search' })}
                                            data-cy="Impersonate_modal__search_box"
                                            value={this.state.search}
                                            onChange={e =>
                                                this.setState({
                                                    search: e.target.value
                                                })
                                            }
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12">
                                    <ReactTable
                                        key={`discounts-table-${this.props.step}`}
                                        className={'light'}
                                        data={selectedMaterialsFiltered}
                                        columns={[
                                            {
                                                id: 'image',
                                                Header: <Translation id="image" defaultMessage="Image" />,
                                                accessor: 'image',
                                                Cell: ({ original }) => {
                                                    original.style = { ...original.style, overflowX: 'visible' }
                                                    return (
                                                        <div className="list-result" data-cy="catalog__product_sample_result" type={original.type}>
                                                            <DisplayOutletIndicator outletQuality={original.isSellingOutlet && original.outletQuality} style={{ top: 'initial' }} />
                                                            <div className="product-image-container">
                                                                {original.thumbMd ? (
                                                                    <img style={{ maxWidth: '100%' }} src={original.thumbMd} />
                                                                ) : (
                                                                    <img style={{ maxWidth: '100%', height: '76px' }} />
                                                                )}
                                                            </div>
                                                        </div>
                                                    )
                                                },
                                                sortable: false,
                                                filterable: false
                                            },
                                            {
                                                id: 'name',
                                                Header: <Translation id="name" defaultMessage="Name" />,
                                                accessor: 'name',
                                                Cell: ({ original }) => {
                                                    return (
                                                        <div className="d-flex flex-column justify-content-center align-items-center h-100">
                                                            <ProductDisplayName product={original} />
                                                            <DisplayBrand brand={original.brand} />
                                                            <span style={{ fontSize: '0.8em' }}># {original.productId}</span>
                                                        </div>
                                                    )
                                                },
                                                filterable: false
                                            },
                                            {
                                                id: 'thickness',
                                                Header: <Translation id="thickness" defaultMessage="Thickness" />,
                                                Cell: ({ original }) => {
                                                    return (
                                                        <div className="d-flex justify-content-center align-items-center h-100">
                                                            <DisplayThickness thickness={original.thickness} />
                                                        </div>
                                                    )
                                                },
                                                filterable: false
                                            },
                                            {
                                                id: 'finish',
                                                Header: <Translation id="finish" defaultMessage="Finish" />,
                                                accessor: 'finish',
                                                Cell: ({ original }) => {
                                                    return (
                                                        <div className="d-flex justify-content-center align-items-center h-100">
                                                            <DisplayDetail class="finish" value={original.finish} />
                                                        </div>
                                                    )
                                                },
                                                filterable: false
                                            },
                                            {
                                                id: 'type',
                                                Header: <Translation id="type" defaultMessage="Type" />,
                                                accessor: 'type',
                                                Cell: ({ original }) => {
                                                    return (
                                                        <div className="d-flex justify-content-center align-items-center h-100 text-wrap">
                                                            {typeMap[original.type] ? typeMap[original.type] : <DisplayDetail class="finish" value={original.type} />}
                                                        </div>
                                                    )
                                                },
                                                filterable: false
                                            },
                                            {
                                                id: 'subtypeName',
                                                accessor: 'subtypeName',
                                                Header: <Translation id="subtypeName" defaultMessage="Subtype" />,
                                                Cell: ({ original }) => {
                                                    return (
                                                        <div className="d-flex justify-content-center align-items-center h-100 text-wrap">
                                                            <DisplayDetail class="finish" value={original.subtypeName} />
                                                        </div>
                                                    )
                                                },
                                                filterable: false
                                            },
                                            {
                                                id: 'rotation',
                                                accessor: 'indRotation',
                                                Header: <Translation id="rotation" defaultMessage="Rotation" />,
                                                Cell: ({ original }) => {
                                                    let label = ''

                                                    if (original.indRotation) {
                                                        let lang = this.props.language.toUpperCase()
                                                        if (!(lang in original.translations)) lang = 'Z2'
                                                        if (lang in original.translations && original.translations[lang].indRotation) {
                                                            label = `(${original.indRotation}) ${original.translations[lang].indRotation}`
                                                        }
                                                    }

                                                    return (
                                                        <div className="d-flex h-100 text-wrap">
                                                            <DisplayDetail class="finish" value={label} />
                                                        </div>
                                                    )
                                                },
                                                filterable: false
                                            },
                                            {
                                                id: 'actions',
                                                Header: <Translation id="request-discount" defaultMessage="Request discount" />,
                                                Cell: ({ original }) => {
                                                    return (
                                                        <div className="d-flex justify-content-center align-items-center h-100">
                                                            <input
                                                                type="checkbox"
                                                                aria-label="Checkbox for following text input"
                                                                style={{ filter: 'grayscale(1)', width: '1.4em', height: '1.4em' }}
                                                                checked={
                                                                    this.props.discountMaterials[original.productId] &&
                                                                    this.props.discountMaterials[original.productId].requestedDiscount
                                                                }
                                                                disabled={!this.canAskForDiscount(original)}
                                                                onChange={e => this.markMaterial(original.productId, e.target.checked)}
                                                            />
                                                            {/*<span className="bt-delete" onClick={() => this.removeMaterial(original)} style={{ cursor: 'pointer' }}>*/}
                                                            {/*    <i className="fal fa-times" />*/}
                                                            {/*</span>*/}
                                                        </div>
                                                    )
                                                },
                                                sortable: false,
                                                filterable: false
                                            }
                                        ]}
                                        defaultPageSize={4}
                                        pageSizeOptions={[4, 8, 10, 25, 50, 100]}
                                        previousText={<Translation id="previous" defaultMessage="Previous" />}
                                        nextText={<Translation id="next" defaultMessage="Next" />}
                                        pageText={<Translation id="page" defaultMessage="Page" />}
                                        ofText={<Translation id="of" defaultMessage="Of" />}
                                        onPageChange={() => this.forceUpdate()}
                                    />
                                    {formHandleSubmit}
                                </div>
                            </div>
                        </div>
                    </React.Fragment>
                ) : (
                    <React.Fragment>
                        {headerStep}
                        <div className="container request-discount-question">
                            <div className="row">
                                <div className="col-12 m-0 p-0">
                                    <div>
                                        <p className="rdq-main-message">
                                            <strong>
                                                <Translation
                                                    id="request_discount_block_main_message"
                                                    defaultMessage="You cannot apply for discounts for this campaign because the start date is less than 7 calendar days."
                                                />
                                            </strong>
                                        </p>
                                    </div>
                                    <div>
                                        <p className="rdq-question">
                                            <Translation id="request_discount_block_ask_question" defaultMessage="What do you want to do?" />
                                        </p>
                                    </div>
                                </div>
                                <div className="col-12 m-0 p-0 buttons-row-rdq">
                                    <div>
                                        <Button className="promotion-button promotion-button-back" onClick={e => this.goToStepOne(e)}>
                                            <Translation id="request_discount_block_return_step_one" defaultMessage="Return to step 1 to change start date" />
                                        </Button>
                                    </div>
                                    <div>
                                        {formHandleSubmit}
                                        <button className="promotion-button inverted" type="submit" form="form_current_step">
                                            <Translation id="request_discount_block_continue" defaultMessage="Continue to the next step without requesting discounts" />
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </React.Fragment>
                )}
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        token: getToken(state),
        language: getLanguage(state),
        specialNames: getSpecialNames(state, 'specialNames'),
        discountMaterials: getDiscountMaterials(state)
    }
}
const mapDispatchToProps = dispatch => {
    return {
        requestDiscounts: () => dispatch(requestMaterialsDiscounts())
    }
}
export default ChooseDiscounts = withRouter(injectIntl(connect(mapStateToProps, mapDispatchToProps)(ChooseDiscounts)))
