import React from 'react'

import { connect } from 'react-redux'
import {getSalesOrg, getSpecialNames} from '../../store/login/reducers'
import { injectIntl } from 'react-intl'
const mapStateToProps = state => {
    return {
        specialNames: getSpecialNames(state, 'specialNames'),
        salesOrg: getSalesOrg(state)
    }
}
const LANG_TO_INDEX = {
    da: 'EN',
    de: 'DE',
    'en-US': 'Z2',
    en: 'EN',
    es: 'ES',
    fi: 'EN',
    'fr-CA': 'FR',
    'en-CA': 'EN',
    fr: 'FR',
    it: 'IT',
    nl: 'NL',
    no: 'EN',
    pt: 'PT',
    pl: 'EN',
    sv: 'SV',
    tr: 'TR'
}

class ProductDisplayName extends React.Component {
    render() {
        const { product, specialNames, style } = this.props

        let locale = LANG_TO_INDEX[this.props.intl.locale]

        let showName //= product.displayName.toLowerCase()

        if (specialNames && specialNames[product.productId]) {
            showName = specialNames[product.productId]
        } else if (product.translations && product.translations[locale]) {
            showName = product.translations[locale].displayName.toLowerCase()
        } else {
            showName = product.displayName ? product.displayName.toLowerCase() : ''
        }
        showName = showName.replace(/,/g , ', ')

        //Temporal hasta que la descripción no llegue bien del maestro de materiales
        if (locale === 'Z2') {
            showName = showName.replace('woo' , 'w/o overflow')
            showName = showName.replace('lavabo' , 'wba')
            showName = showName.replace('aca ing' , 'mitered')
            showName = showName.replace('faldón' , 'skirt')
            showName = showName.replace('c/recto' , 'strt edge')
        }

        if ((product.colorId === 'TAK' || product.colorId === 'MTR') && this.props.salesOrg != '2012') showName = 'TAJ MAHAL';

        return (
            <span className="product-title" data-cy="global__product_display_name" style={style}>
                {showName}
            </span>
        )
    }
}
export default injectIntl(
    connect(
        mapStateToProps,
        null
    )(ProductDisplayName)
)
