import React from 'react'
import { connect } from 'react-redux'
import Translation from '../../global/Translation'
import { FormattedHTMLMessage } from 'react-intl'
import backgroundImage from '../../../assets/img/banner-silestone/banner-silestone-background.png'
import { isOnlyUsaSalesOrg } from '../../../store/centers/reducers'

const mapStateToProps = state => {
    return {
        isOnlyUsaSalesOrg: isOnlyUsaSalesOrg(state),
    }
}

class BannerSilestone extends React.Component {
    constructor(props) {
        super(props)

        this.discount = '10%'
    }

    render() {
        const { className, isCheckoutView, isOnlyUsaSalesOrg } = this.props

        const footerDisclaimer = (
            <span>
                <Translation
                    id="silestone_flash_promo_info_discount"
                    defaultMessage="Please note that discount will not be visible in your shopping cart, but it will be reflected in your invoice."
                />
            </span>
        )

        const headerContent = (
            <div>
                <h2>
                    <Translation id="silestone_full_truck_flash_promo" defaultMessage="Silestone® Full truck Flash Promo" />
                </h2>
                <h3>
                    <Translation id="february_3_7_2025" defaultMessage="February 3rd - 7th 2025" />
                </h3>
            </div>
        )

        const discountContent = (
            <React.Fragment>
                <div className="number-percent">
                    <span>{this.discount}</span>
                </div>
                <div className="text-percent">
                    <div>
                        <span>
                            <Translation id="additional_discount" defaultMessage="additional discount" />
                        </span>
                    </div>
                    <div>
                        <span>
                            <Translation id="and_special_120_day_terms" defaultMessage="and special 120-day terms." />
                        </span>
                    </div>
                </div>
            </React.Fragment>
        )

        const usaFooterContent = (
            <span>
                <FormattedHTMLMessage
                    id="includes_all_silestone_sensa_natural_stone_color"
                    defaultMessage="Includes all <b>Silestone®</b>, <b>Sensa</b> and <b>Natural Stone</b> colors, thicknesses, and finishes. The possibilities are endless."
                />
            </span>
        )

        const canadaFooterContent = (
            <span>
                <FormattedHTMLMessage
                    id="includes_all_silestone_sensa_natural_stone_color_3"
                    defaultMessage="Includes all <b>Silestone®</b>, <b>Sensa</b> and <b>Natural Stone</b> colors, and finishes in 3cm thickness. The possibilities are endless."
                />
            </span>
        )

        return (
            <React.Fragment>
                {!isCheckoutView ? (
                    <div
                        className={`big__banner__silestone ${className}`}
                        style={{
                            backgroundImage: `url(${backgroundImage})`,
                            backgroundRepeat: 'no-repeat',
                            backgroundSize: 'cover',
                            backgroundPosition: 'bottom'
                        }}
                    >
                        <div className="big__banner__silestone--header">
                            <div className="left-side">
                                {headerContent}
                                <div className="d-none d-md-block">
                                    <div className="left-side--footer">{footerDisclaimer}</div>
                                </div>
                            </div>

                            <div className="right-side">
                                <div className="right-side--content">{discountContent}</div>
                                <div className="right-side--footer">
                                    {isOnlyUsaSalesOrg ? (
                                        usaFooterContent
                                    ) : (
                                        canadaFooterContent
                                    )}
                                </div>
                                <div className="d-block d-md-none">
                                    <div className="left-side--footer">{footerDisclaimer}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                ) : (
                    <div
                        className="small__banner__silestone"
                        style={{
                            backgroundImage: `url(${backgroundImage})`,
                            backgroundRepeat: 'no-repeat',
                            backgroundSize: 'cover'
                        }}
                    >
                        <div className="small__banner__silestone--title">{headerContent}</div>
                        <div className="small__banner__silestone--content">{discountContent}</div>
                        <div className="small__banner__silestone--footer">
                            {isOnlyUsaSalesOrg ? (
                                usaFooterContent
                            ) : (
                                canadaFooterContent
                            )}
                        </div>
                        <div className="small__banner__silestone--discount">{footerDisclaimer}</div>
                    </div>
                )}
            </React.Fragment>
        )
    }
}

export default connect(mapStateToProps, null)(BannerSilestone)