import React, {Component} from 'react'
import {connect} from "react-redux";
import LocalizedLink from '../../global/LocalizedLink'
import Translation from '../../global/Translation'
import ConfigBathCard from './ConfigBathCard'
import CatalogFilter from './CatalogFilters'
import {canFilterQuickShip, getConfigurableModels} from '../../../store/cut-to-size/reducers'
import { ReactComponent as QuickShipIcon } from '../../../assets/img/cut-to-size/quickShipIcon.svg'
import {Switch} from "@progress/kendo-react-inputs/dist/es/switch/Switch";
import {cloneDeep} from "lodash";
import {FormattedHTMLMessage} from "react-intl";


class ConfigurableBathsCatalog extends Component {
    constructor(props) {
        super(props)
        this.state = {
            selectedOptionType: null,
            selectedOptionModel: null,
            selectedOptionBrand: null,
            selectedOptionColor: null,
            showOnlyQuickShip: props.canFilterQuickShip,
            filteredModels: []
        }
    }

    handleChangeType = selectedOption => {
        this.setState({ selectedOptionType: selectedOption })
    }

    handleChangeModel = selectedOption => {
        this.setState({ selectedOptionModel: selectedOption })
    }

    handleChangeBrand = selectedOption => {
        this.setState({ selectedOptionBrand: selectedOption })
    }

    handleChangeColor = selectedOption => {
        this.setState({ selectedOptionColor: selectedOption })
    }

    selectQuickShip = isChecked => {
        this.setState({showOnlyQuickShip: isChecked.value})
    }


    getFilteredModelsAndColors() {
        const { configurableModels } = this.props
        let filteredModels = configurableModels && configurableModels.models ? cloneDeep(configurableModels.models) : []

        filteredModels = filteredModels.filter(row => !!row.defaultImageModelUrl)

        if (this.state.showOnlyQuickShip) {
            filteredModels = filteredModels.filter(row => row.third_party === true)
            filteredModels.forEach(filteredModel => {
                Object.entries(filteredModel.fullConfig.brands).forEach(([brand, config]) => {
                    filteredModel.fullConfig.brands[brand] = config.filter(c => c.thirdParty.length > 0)
                })
            })
        }



        if (this.state.selectedOptionModel) {
            filteredModels = filteredModels.filter(row => row.model === this.state.selectedOptionModel.value)
        }
        
        if (this.state.selectedOptionType) {
            filteredModels = filteredModels.filter(row => row.type === this.state.selectedOptionType.value)
        }

        if (this.state.selectedOptionBrand) {
            filteredModels = filteredModels.filter(row => !!row.fullConfig.brands[this.state.selectedOptionBrand.value])
        }

        if (this.state.selectedOptionColor) {
                filteredModels = filteredModels.filter(row => {
                    if (this.state.selectedOptionBrand) {
                        return row.fullConfig.brands[this.state.selectedOptionBrand.value].filter(c => c.color === this.state.selectedOptionColor.value).length > 0
                    } else {
                        let brandConfigs = []
                        Object.values(row.fullConfig.brands).forEach(c => brandConfigs = brandConfigs.concat(c))
                        console.log('brandsConf', this.state.selectedOptionColor, brandConfigs)
                        return brandConfigs.filter(c => c.color === this.state.selectedOptionColor.value).length > 0
                    }
                })
        }

        const availableColors = {}
        const availableBrands = {}
        const availableTypes = {}

        filteredModels.forEach(filteredModel => {
            availableTypes[filteredModel.type] = filteredModel.type

            Object.entries(filteredModel.fullConfig.brands).forEach(([brand, config]) => {
                if (config.length > 0) {
                    if (!this.state.selectedOptionColor
                        || (this.state.selectedOptionColor && config.find(c => c.color === this.state.selectedOptionColor.value))
                    ) availableBrands[brand] = brand
                }

                if (!this.state.selectedOptionBrand
                    || (this.state.selectedOptionBrand && this.state.selectedOptionBrand.value === brand)) {
                    config.forEach(c => {
                        if (c.colorName) availableColors[c.color] = c.colorName
                    })
                }
            })
        })

        return { filteredModels, availableColors, availableBrands, availableTypes }
    }
    
    render() {
        const { filteredModels, availableColors, availableBrands, availableTypes } = this.getFilteredModelsAndColors()

        return (
            <div className="catalog__configurable__baths">
                {/* Go back btn */}
                <div className="catalog__configurable__baths--goback">
                    <LocalizedLink routeId={'ROUTE_CUT_TO_SIZE'} queryString={''} params={{ outlet: '' }}>
                        <i className="fas fa-chevron-left" />
                        <span>
                            <Translation id="back_select_elaborated_type" defaultMessage="Back to elaborated type selection" />
                        </span>
                    </LocalizedLink>
                </div>

                {/* Page title */}
                <div className="catalog__configurable__baths--page-title">
                    <h1><Translation id="washbasing_and_shower_trays" defaultMessage="Washbasins and shower trays" /></h1>
                </div>

                {/* Filters */}
                <div className="catalog__configurable__baths--filters">
                    {this.props.canFilterQuickShip &&
                        <div className="quick-ship">

                                <div className="first-line">
                                    <Switch onChange={this.selectQuickShip}
                                            checked={this.state.showOnlyQuickShip}
                                            onLabel={""}
                                            offLabel={""}
                                    />
                                    <QuickShipIcon/>
                                    {
                                        this.state.showOnlyQuickShip ?
                                            <span className="first-txt"><Translation id="showing_quickship" defaultMessage="Showing QuickShip products"/></span>
                                            :
                                            <span className="first-txt"><Translation id="hiding_quickship" defaultMessage="Hiding QuickShip products"/></span>
                                    }
                                </div>

                            {
                                this.state.showOnlyQuickShip ?
                                    <React.Fragment>
                                        <div className="second-txt"><FormattedHTMLMessage id="quick_ship_estimated_time" defaultMessage="Fast delivery: Estimated time to deliver is <b>2-4 weeks</b>"/></div>
                                        <div className="third-txt"><Translation id="quick_ship_available_colors" defaultMessage="Quickship products available on selected colors catalogue"/></div>
                                    </React.Fragment>
                                    :
                                    <div className="second-txt"><Translation id="no_quick_ship_estimated_time" defaultMessage="Estimated time to deliver is 12 weeks"/></div>
                            }


                        </div>
                    }
                    <div className="filter-by">
                        <span><Translation id="filter_by" defaultMessage="Filter by" />:</span>
                    </div>
                    <div>
                    <CatalogFilter
                            configurableModels={filteredModels}
                            uniqueColors={availableColors}
                            uniqueBrands={availableBrands}
                            uniqueTypes={availableTypes}
                            handleChangeType={this.handleChangeType}
                            handleChangeModel={this.handleChangeModel}
                            handleChangeBrand={this.handleChangeBrand}
                            handleChangeColor={this.handleChangeColor}
                            selectedOptionType={this.state.selectedOptionType}
                            selectedOptionModel={this.state.selectedOptionModel}
                            selectedOptionBrand={this.state.selectedOptionBrand}
                            selectedOptionColor={this.state.selectedOptionColor}
                        />
                    </div>
                </div>

                {/* Materials */}
                <div className="catalog__configurable__baths--materials">
                    {filteredModels &&
                        filteredModels.map(item => {
                            return <ConfigBathCard data={item} key={item.model + '_' + item.type} fabricator={this.state.showOnlyQuickShip ? 'local' : 'global'}/>
                        })}
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        configurableModels: getConfigurableModels(state),
        canFilterQuickShip: canFilterQuickShip(state)
    }
}

export default connect(
        mapStateToProps,
        null
    )(ConfigurableBathsCatalog)
