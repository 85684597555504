import { getLocaleWithft2 } from './store/products/reducers'

import moment from "moment";
import {isProductOutlet} from "./components/landing-templates/utils";

export function getReplaceDotWithComma(element, locale, state){
    if(element && state) {
        let replacement = element.toString().replace('.', ',')
        // We need . for EEUU, rest of countries is ,
        if (locale !== 'en-US') {
            return replacement
        }
        return element
    }
}

function copy(text) {
    if (navigator.clipboard) {
        navigator.clipboard.writeText(text)
    } else {
        var textArea = document.createElement('textarea')
        textArea.value = text
        document.body.appendChild(textArea)
        textArea.focus()
        textArea.select()

        try {
            document.execCommand('copy')
        } catch (err) {}

        document.body.removeChild(textArea)
    }
}

export { copy }

const CM_IN_INCHES = 0.393701
const INCH_IN_CM = 2.5400013716

export function convertCentimetersToInches(cm, decimals = 2) {
    return parseFloat((cm * CM_IN_INCHES).toFixed(decimals))
}

export function convertInchesToCentimeters(inches, decimals = 2) {
    return parseFloat((inches * INCH_IN_CM).toFixed(decimals))
}

export function hasImperialUnits(state) {
    if (state) {
        if (state.profileConfiguration.profileConfiguration && state.profileConfiguration.profileConfiguration.lengthUnit) {
            return state.profileConfiguration.profileConfiguration.lengthUnit === 'FT2'
        }
        const localeWithFt = getLocaleWithft2()
        return localeWithFt.includes(state.ui.language)
    }
}

export function getAdaptedFormat(format, params, state) {
    if (format && state) {
        const isImperialUnits = hasImperialUnits(state) 
        let measures = format.split(/[xX]/)
        if (measures.length === 2) {
            let separator = 'x'
            let decimals = isImperialUnits ? 2 : 0
            let units = (params && params.showUnits) ? ` ${getCmOrInches(state)}` : ''
            if (params && params.upper) separator = 'X'
            if (params && params.spaced) separator = ` ${separator} `
            if (params && params.decimals) decimals = params.decimals

            if (isImperialUnits) {
                measures = measures.map(item => {
                    let newMeasures = item.replace(/,/g, '.')
                    return convertCentimetersToInches(parseFloat(newMeasures), decimals)
                })
            }
            return `${measures[0]}${separator}${measures[1]}${units}`
        } else {
            return format
        }
    }
}

export function getMeasureFromMetric(measure, decimals, state) {
    return hasImperialUnits(state) ? convertCentimetersToInches(measure, decimals) : measure
}

export function getMetricMeasureFromInput(measure, decimals, state) {
    return hasImperialUnits(state) ? convertInchesToCentimeters(measure, decimals) : measure
}

export function getCmOrInches(state) {
    return hasImperialUnits(state) ? 'in' : 'cm'
}

export function getNextWorkingDay(days) {
    let plannedDay = moment().startOf('day').add(days, 'days')
    let plannedWeekDay = plannedDay.day()
    if (plannedWeekDay === 0) {
        plannedDay = plannedDay.add(1, 'days')
    }

    return plannedDay
}

export function getCtsMeasures(cts, state) {
    const units = getCmOrInches(state)
    const isImperial = units === 'in';
    if (cts.shape === 'ROUNDED') {
        const diameter = isImperial ? convertCentimetersToInches(cts.width, 1) : cts.width
        return {
            diameter: `${diameter} ${units}`
        }
    } else {
        const measures = {}
        const height = isImperial ? convertCentimetersToInches(cts.height, 1) : cts.height
        const width = isImperial ? convertCentimetersToInches(cts.width, 1) : cts.width
        measures.format = `${width}x${height} ${units}`
        if (cts.shape === 'RECTANGULAR_CURVE_RADIUS') {
            const radius = isImperial ? convertCentimetersToInches(cts.width, 1) : cts.radius
            measures.radius = `${radius} ${units}`
        }
        return measures
    }
}

/**
 * @param {string} str 
 * @returns Returns the first uppercase letter of the string.
 */
export function capitalizeFirstLetter(str) {
    if (typeof str === 'string') return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
    return str
}

// export const getLineProductStock = line => {
//     if (line && line.product) {
//         if (line.product.mall === 'X') {
//             if (line.lots) {
//                 return (line.product.productsLots && line.product.productsLots[line.productId]) ? line.product.productsLots[product.id].stock : 0
//             }
//             else return line.product.productLanding.stock_mall
//         }
//         return !!isProductOutlet(product) ? product.productLanding.stock_outlet : product.productLanding.stock
//     }
//
//     return ''
//     // product && product.productLanding && product.data ? (product.data.outlet ? (product.data.isStockOutlet ? product.productLanding.stock_outlet : product.productLanding.stock) : product.productLanding.stock) : '';
//
// }



export const langFromLocale = {
    'bg' : 'bg-BG',
    'cs' : 'cs-CZ',
    'da' : 'da-DK',
    'de' : 'de-DE',
    'en-CA' : 'en-CA',
    'en' : 'en-GB',
    'en-US' : 'en-US',
    'es' : 'es-ES',
    'et' : 'et-EE',
    'fi' : 'fi-FI',
    'fr-CA' : 'fr-CA',
    'fr' : 'fr-FR',
    'he' : 'he-IL',
    'hr' : 'hr-HR',
    'hu' : 'hu-HU',
    'it' : 'it-IT',
    'ja' : 'ja-JP',
    'lt' : 'lt-LT',
    'lv' : 'lv-LV',
    'nl' : 'nl-NL',
    'no' : 'no-NO',
    'pl' : 'pl-PL',
    'pt' : 'pt-PT',
    'sk' : 'sk-SK',
    'sl' : 'sl-SL',
    'sv' : 'sv-SE',
    'tr' : 'tr-TR'
}

export function convertCentimetersToMillimeters(input) {

    const cm = parseFloat(input)

    if (isNaN(cm)) {
        return 0
    }

    return cm * 10
}

export function getUsaCaPrTermsAndConditionsByLang(lang) {
    const spanishUrl = 'https://assetstools.cosentino.com/api/v1/bynder/doc/C61C1BFD-8D67-4DCD-A33F26E187FDDF65/2023-General-T&C_Cosentino-USA-Canada-PR_Spanish.pdf'
    const englishUrl = 'https://assetstools.cosentino.com/api/v1/bynder/doc/9175801C-0F26-459C-85EA3D656A00C508/2023-General-T&C_Cosentino-USA-Canada-PR.pdf'
    const frenchUrl = 'https://assetstools.cosentino.com/api/v1/bynder/doc/A8649BDC-D9F4-4809-87238C459F0990DF/2023-General-T&C_Cosentino-USA-Canada-PR_French.pdf'

    switch (lang) {
        case 'es':
            return spanishUrl
        case 'en-CA':
            return englishUrl
        case 'fr-CA':
            return frenchUrl
        default:
            return englishUrl
    }
}

/**
 * Validate the email using regex (optimized for efficiency).
 * @param {string} email 
 * @returns {boolean}
 */
export function validateInputEmail(email) {
    const emailRegex = /^(?:[a-zA-Z0-9._%+-]+)@(?:[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})$/
    return emailRegex.test(email)
}