import { createSelector } from 'reselect'
import { LOCATION_CHANGE } from 'react-router-redux'
import {
    getMktoolsBudgetWasted,
    getPricesGroup,
    getSelectedCenter,
    getShippingMethod,
    getSalesOrg,
    getLatinaVitoriaCenterId,
    getNaturalStoneSpainCenterId
} from '../centers/reducers'
import {getNextWorkingDay} from "../../helpers";
import {v4 as uuidv4} from "uuid";
import moment from 'moment';

export const DEFAULT_MIN_DELIVERY_DATE = 1

export const initState = {
    qty: null,
    total: null,
    isAdding: false,
    isAddingCallApi: false,
    showModal: false,
    lines: [],
    alert: null,
    status: null,
    monthItems: null,
    waitTillDate: null,
    reservationCode: null,
    reservationCutToSizeCode: null,
    reservationConfigurableCode: null,
    reservationQuickShipConfigurableCode: null,
    isSending: false,
    comment: null,
    linesPrice: [],
    calculatingLinesPrice: false,
    phase2PricesByProduct: [],
    totalCoin: null,
    vouchers: [],
    voucherStatusText: '',
    isValidVoucher: null,
    isLoadingVoucher: false,
    showNoStockModal: false,
    matsNoStock: [],
    showIncompatibleTypesExceptionModal: false,
    phase2FetchedPrices: null,
    successfullyAddedToCart: false,
    linesDeliveryDates: {},
    deliveryDateControlKey: '0',
    finishStep: false,
    isLoading:false,
    fullUnits: {
        units: null,
        isValid : null,
        qtyForNextUnit: null,
        currentQtyWeight: null,
        currentMinWeight: null,
        currentMaxWeight: null
    },
    linesSlaDate: [],
    preferredDeliveryDateByLines: {},
    isLoadingLinesSlaDate: false,
    isCheckoutPage: false,
    isDefaultDeliveryDate: false,
    hideSlaDatesForCts: false,
    linesSlaDateVisibility: {},
    isChangingCartQty: false
}

const cart = (state = initState, action) => {
    switch (action.type) {
        case LOCATION_CHANGE:
            return Object.assign({}, state, { showModal: false, showCheckoutModal: false, alert: null })
        case 'SUBMIT_CHECKOUT_REQUEST':
            return Object.assign({}, state, { isSending: true })
        case 'SUBMIT_CHECKOUT_SUCCESS':
            return Object.assign({}, state, {
                reservationCutToSizeCode: action.cutToSizeCode,
                reservationConfigurableCode: action.configurableCode,
                reservationQuickShipConfigurableCode: action.quickShipConfigurableCode,
                reservationCode: action.code,
                isSending: false,
                voucher: '',
                voucherStatusText: '',
                isLoadingVoucher: false
            })
        case 'TRANSFORM_SKU_TO_LOTS_REQUEST': {
            return Object.assign({}, state, { transformingSkuToLots: true })
        }
        case 'TRANSFORM_SKU_TO_LOTS_SUCCESS': {
            return Object.assign({}, state, { transformingSkuToLots: false })
        }
        case 'TRANSFORM_SKU_TO_LOTS_FAILURE':
            return Object.assign({}, state, { transformingSkuToLots: false, alert: `${action.payload.statusText}` })

        case 'SUBMIT_CHECKOUT_FAILURE':
            return Object.assign({}, state, {
                alert: `${action.payload.statusText}`,
                status: `${action.payload.status}`,
                monthItems: `${action.payload.monthItems}`,
                waitTillDate: `${action.payload.waitTillDate}`,
                isSending: false
            })
        case 'SHOW_CHECKOUT_MODAL':
            return Object.assign({}, state, { showCheckoutModal: true })
        case 'CLOSE_CHECKOUT_MODAL':
            return Object.assign({}, state, { showCheckoutModal: false })
        case 'SHOW_CART_MODAL':
            return Object.assign({}, state, { showModal: true })
        case 'CLOSE_CART_MODAL':
            return Object.assign({}, state, { showModal: false })
        case 'FETCH_CART_REQUEST':
            return Object.assign({}, state, { isLoading:true })
        case 'FETCH_CART_FAILURE':
            return Object.assign({}, state, { isLoading:false })
        case 'FETCH_CART_SUCCESS':
            return Object.assign({}, state, { lines: action.cart.lines, vouchers: action.cart.vouchers,isLoading:false })
        case 'EMPTY_CART':
            return { ...state, lines: [], vouchers: [], isLoadingVoucher: false, isValidVoucher: null, voucherStatusText: '' }
        case 'ADD_CART_LINE_REQUEST':
        case 'ADD_CART_BULK_LOTS_REQUEST':
            return { ...state, isAdding: true, successfullyAddedToCart: false }
        case 'ADD_CART_LINE_CALL_API_REQUEST':
        case 'ADD_CART_BULK_LOTS_CALL_API_REQUEST':
            return { ...state, isAddingCallApi: true }

        case 'ADD_CART_LINE_CALL_API_SUCCESS':
        case 'ADD_CART_BULK_LOTS_CALL_API_SUCCESS':
            return { ...state, isAddingCallApi: false }

        case 'REMOVE_CART_LINE':
            return { ...state, lines: state.lines.filter(lines => lines.lineId !== action.lineId) }
        case 'TOGGLE_INCOMPATIBLE_TYPES_EXCEPTION_MODAL': {
            return { ...state, showIncompatibleTypesExceptionModal: !state.showIncompatibleTypesExceptionModal }
        }
        case 'ADD_CART_LINE_FAILURE':
            if (action.payload.statusText === 'CART_TYPE_INCOMPATIBLE_EXCEPTION') {
                return {
                    ...state,
                    showIncompatibleTypesExceptionModal: true,
                    alert: `Add To cart Error: ${action.payload.status} ${action.payload.statusText}`,
                    isAdding: false,
                    isAddingCallApi: false
                }
            }
            return { ...state, alert: `Add To cart Error: ${action.payload.status} ${action.payload.statusText}`, isAdding: false, isAddingCallApi: false }
        case 'CLOSE_SEGMENTATION_MODAL':
        case 'CLOSE_DEKTON_GRIP_MODAL':
        case 'ADD_CART_LINE_SUCCESS':
        case 'ADD_CART_BULK_LOTS_SUCCESS':
            return { ...state, isAdding: false, isAddingCallApi: false, successfullyAddedToCart: true }
        case 'ADD_CART_LINE':
            return { ...state, lines: [action.line].concat(state.lines) }
        case 'CHANGE_CART_LINE_QTY':
            return {
                ...state,
                lines: state.lines.map(line => {
                    if (line.lineId === action.lineId) {
                        return { ...line, qty: action.qty }
                    }
                    return line
                })
            }
        case 'CHANGE_CART_LINE_COMMENT':
            return {
                ...state,
                lines: state.lines.map(line => {
                    if (line.lineId === action.lineId) {
                        return { ...line, comment: action.commentsWithouthEmojis }
                    }
                    return line
                })
            }
        case 'CHECK_LINES_PRICE_REQUEST':
            return {
                ...state,
                calculatingLinesPrice: true
            }
        case 'CHECK_LINES_PRICE_SUCCESS':
            let total = calculateVistexTotal(action.data)
            return {
                ...state,
                calculatingLinesPrice: false,
                linesPrice: action.data,
                totalAmount: total.amountTotal,
                totalCoin: total.coin
            }
        case 'CHECK_LINES_PRICE_FAILURE':
            return {
                ...state,
                calculatingLinesPrice: false
            }

        case 'STORE_PRICE_PHASE2_PRODUCT_BY_CLIENT':
            if (!state.phase2FetchedPrices) {
                state.phase2FetchedPrices = new Map()
            }
            state.phase2FetchedPrices.set(action.productId, action.userName)
            return state
        case 'CLEAR_PHASE2_PRODUCT_BY_CLIENT':
            if (state.phase2FetchedPrices) state.phase2FetchedPrices.clear()
            return Object.assign({}, state, { phase2PricesByProduct: [] })
        case 'CHECK_PRICE_PHASE2_BY_PRODUCT_REQUEST':
            return { ...state, calculatingLinesPrice: true }
        case 'CHECK_PRICE_PHASE2_BY_PRODUCT_SUCCESS':
            return {
                ...state,
                calculatingLinesPrice: false,
                phase2PricesByProduct: [
                    {
                        price: action.data,
                        productId: action.productId
                    }
                ].concat(...state.phase2PricesByProduct)
            }
        case 'CHECK_PRICE_PHASE2_BY_PRODUCT_FAILURE':
            return { ...state, calculatingLinesPrice: false }
        case 'CART_CHECK_VALID_VOUCHER_REQUEST':
        case 'CART_REMOVE_VOUCHER_REQUEST':
            return { ...state, isLoadingVoucher: true }
        case 'CART_CHECK_VALID_VOUCHER_SUCCESS':
        case 'CART_REMOVE_VOUCHER_SUCCESS':
            return { ...state, isLoadingVoucher: false, voucherStatusText: '', lines: action.cart.lines, vouchers: action.cart.vouchers }
        case 'CART_CHECK_VALID_VOUCHER_FAILURE':
        case 'CART_REMOVE_VOUCHER_FAILURE':
            return { ...state, isLoadingVoucher: false, voucherStatusText: action.voucherStatusText, isValidVoucher: action.isValidVoucher }

        case 'CART_CHECK_VALID_VOUCHER_RESET':
            return { ...state, vouchers: [], isLoadingVoucher: false, isValidVoucher: null, voucherStatusText: '' }
        case 'RESET_UI':
            return Object.assign({}, state, { showModal: false })
        case 'SET_SHOW_MODAL_NOSTOCK':
            return Object.assign({}, state, { showNoStockModal: action.value })
        case 'PRODUCT_WITHOUT_STOCK': {
            return Object.assign({}, state, {
                showNoStockModal: true,
                matsNoStock: action.data,
                isSending: false
            })
        }
        case 'SIMPLE_LOGIN_SUCCESS':
        case 'SIMPLE_IMPERSONATE_SUCCESS':
            if (state.phase2FetchedPrices) state.phase2FetchedPrices.clear()
            return Object.assign({}, state, { phase2PricesByProduct: [] })
        case 'IS_CHECKOUT_PAGE_SCREEN':
            return Object.assign({}, state, {isCheckoutPage: action.isCheckoutPage})
        case 'CART_SET_LINE_SLA_DATE_REQUEST':
            return Object.assign({}, state, {isLoadingLinesSlaDate: true})
        case 'CART_SET_LINE_SLA_DATE_SUCCESS':
            return Object.assign({}, state, {linesSlaDate: action.linesSlaDate, isLoadingLinesSlaDate: false})
        case 'CART_SET_LINE_SLA_DATE_FAILURE':
            return Object.assign({}, state, {isLoadingLinesSlaDate: false})
        case 'CART_SET_LINE_DELIVERYDATE':
            state.linesDeliveryDates[action.lineId] = action.deliveryDate
            return Object.assign({}, state, { deliveryDateControlKey: uuidv4() })
        case 'CART_UNSET_LINE_DELIVERYDATE':
            delete state.linesDeliveryDates[action.lineId]
            return Object.assign({}, state, { deliveryDateControlKey: uuidv4() })
        case 'CART_SET_ALL_LINES_DELIVERYDATES':
            const handleLinesSlaDateVisibility = {}
            if (state.linesSlaDate && action.deliveryDate) {
                Object.keys(state.linesSlaDate).forEach(key => {
                    const slaData = state.linesSlaDate[key]
                    const slaDate = slaData.naturalDays ? moment().add(slaData.naturalDays, 'days') : moment()
                
                    handleLinesSlaDateVisibility[key] = action.deliveryDate.isAfter(slaDate, 'days')
                })
            }

            state.lines.forEach(l => (state.linesDeliveryDates[l.lineId] = action.deliveryDate))

            return Object.assign({}, state, { deliveryDateControlKey: uuidv4(), isDefaultDeliveryDate: action.isDefaultDeliveryDate, linesSlaDateVisibility: handleLinesSlaDateVisibility })
        case 'CART_UNSET_ALL_LINES_DELIVERYDATES':
            const defaultDeliveryDate = getNextWorkingDay(DEFAULT_MIN_DELIVERY_DATE)
            const linesSlaDate = state.linesSlaDate
            state.lines.forEach(l => {
                const selectedLine = linesSlaDate && linesSlaDate[l.lineId]
                if (selectedLine && typeof selectedLine.naturalDays === 'number' && selectedLine.naturalDays && selectedLine.naturalDays > 0) {
                    delete state.linesDeliveryDates[l.lineId]
                } else {
                    state.linesDeliveryDates[l.lineId] = defaultDeliveryDate
                }
            })
            return Object.assign({}, state, { deliveryDateControlKey: uuidv4(), isDefaultDeliveryDate: false })
        case 'ON_FINISH_STEP':
            return Object.assign({}, state, { finishStep: true })
        case 'SET_FULL_UNITS_FOR_CART':
            return Object.assign({}, state, {fullUnits: action.unitsForQty})
        case 'CART_SET_ALL_LINES_DELIVERYDATES_CTS':
            const ctsLines = state.lines && state.lines.filter(line => line.cutToSize !== null)
            ctsLines.forEach((l) => {
                state.linesDeliveryDates[l.lineId] = action.deliveryDateCts
            })

            if (action.deliveryDateCts && action.slaDateState) {
                if (action.deliveryDateCts.isAfter(action.slaDate, 'days')) {
                    return Object.assign({}, state, { hideSlaDatesForCts: true })
                }
            }

            return {...state}
        case 'CHANGE_CART_LINE_QTY_REQUEST_START':
            return { ...state, isChangingCartQty: true }
        case 'CHANGE_CART_LINE_QTY_REQUEST_FINISH':
            return { ...state, isChangingCartQty: false }
        default:
            return state
    }
}
export const showModalSelector = state => {
    return state.cart.showModal
}
export const showIncompatibleTypesExceptionModal = state => {
    return state.cart.showIncompatibleTypesExceptionModal
}
export const showCheckoutModalSelector = state => {
    return state.cart.showCheckoutModal
}
export const getIncentivesValue = state => {
    return state.cart.incentivesValue
}
export const getLines = state => {
    return state.cart.lines
}
export const getLinesPrice = state => {
    return state.cart.linesPrice
}
export const getIsLoading = state => {
    return state.cart.isLoading
}
export const getIsAdding = state => {
    return state.cart.isAdding
}
export const getIsAddingCallApi = state => {
    return state.cart.isAddingCallApi
}
export const getAlert = state => {
    return state.cart.alert
}
export const getStatus = state => {
    return state.cart.status
}
export const getMonthItems = state => {
    return state.cart.monthItems
}

export const getWaitTillDate = state => {
    return state.cart.waitTillDate
}
export const getReservationCode = state => {
    return state.cart.reservationCode
}
export const getReservationCutToSizeCode = state => {
    return state.cart.reservationCutToSizeCode
}
export const getReservationConfigurableCode = state => {
    return state.cart.reservationConfigurableCode
}
export const getReservationQuickShipConfigurableCode = state => {
    return state.cart.reservationQuickShipConfigurableCode
}
export const isSending = state => {
    return state.cart.isSending
}
export const byLineId = (state, lineId) => {
    let found = state.cart.lines.find(line => {
        return line.lineId === lineId
    })

    return found
}
const linesSelector = state => {
    return state.cart.lines
}
export const weightSelector = createSelector(
    linesSelector,
    lines => lines.reduce((total, line) => total + ((line.product && !line.cutToSize) ? line.product.weight : 0) * line.qty, 0)
)
export const getCalculatingLinesPrice = state => {
    return state.cart.calculatingLinesPrice
}
export const calculateVistexTotal = linesPrice => {
    // let coin = Object.values(linesPrice) && Object.values(linesPrice)[0] && Object.values(linesPrice)[0]['CT_OUT_WEB'][0]['CURRENCY']
    let coin = Object.values(linesPrice)[0].coin

    const coins = {}

    let amountTotal = Object.values(linesPrice).reduce((total, line) => {
        let price = 0
        // si el producto tiene precio de su grupo
        // Si la llamada de precios fase II no tiene clase de coste ZT00, no se suma al total ya que aparece la línea como "precio No disponible"
        if (line.hasZT00) {
            price = line.price
        }
        coins[line.coin] = true;

        // let qty = line.qty
        // return total + price * qty

        return total + price
    }, 0)

    if (Object.values(coins).length > 1) amountTotal = 0;

    return { amountTotal, coin }
}
export const totalSelector = state => {
    return state.cart.totalAmount
}
export const totalCoin = state => {
    return state.cart.totalCoin
}
export const totalSelectorMktools = state => {
    let group = getPricesGroup(state)
    return state.cart.lines.reduce((total, line) => {
        if (!line.product) return 0
        let price = 0
        // si el producto tiene precio de su grupo

        if (line.product.pricesMktools && line.product.pricesMktools[group]) price = line.product.pricesMktools[group].price
        // si tiene default
        else if (line.product.pricesMktools && line.product.pricesMktools.default && line.product.pricesMktools.default.price) {
            price = line.product.pricesMktools.default.price
        }
        // si no tiene default el producto vale 0
        return total + price * line.qty
    }, 0)
}
export const getBudgetPlusTotal = state => {
    return getMktoolsBudgetWasted(state) + totalSelectorMktools(state)
}
export const qtySelector = createSelector(
    linesSelector,
    lines => parseFloat(lines.reduce((total, line) => parseFloat(total) + parseFloat(line.qty), 0))
)
export const getCartSlaDays = state => {
    if (!state.cart.lines || state.cart.lines.length === 0) return DEFAULT_MIN_DELIVERY_DATE
    const sla = state.cart.lines.map(v => v.product.days_sla).reduce((prev, current) => Math.max(prev, current))
    if (!sla || sla === 0) return DEFAULT_MIN_DELIVERY_DATE
    return sla
}
export const getCartTypes = state => {
    let types = []
    state.cart.lines.forEach(line => {
        if (line.bundleId !== null) {
            if (!types.includes('BUNDLE')) types.push('BUNDLE')
        } else if (line.lotId !== null) {
            if (!types.includes('LOT')) types.push('LOT')
        } else if (line.productId === 'CUT_TO_SIZE') {
            if (!types.includes('CUT_TO_SIZE')) types.push('CUT_TO_SIZE')
        } else if (line.extra && line.extra.mall) {
            if (!types.includes('MALL')) types.push('MALL')
        } else {
            if (!types.includes('NORMAL')) types.push('NORMAL')
        }
    })
    return types
}

export const getCartBrands = state => {
    let brands = []
    state.cart.lines.forEach(line => {
        if (!brands.includes(line.brand)) {
            brands.push(line.brand)
        }
    })
    return brands
}
export const getHaveSomeProductsBelowThreshold = state => {
    return state.cart.lines.some(line => line.product.threshold && line.product.threshold >= line.product.stock)
}
export const getHaveSomeProductsWithoutSapStock = state => {
    let productQtys = {}
    var matWithoutStock = []
    if (state.cart.matsNoStock == []) {
        return []
    }

    matWithoutStock = state.cart.lines.map(line => {
        var matStock = Object.assign({}, line)
        var isOutlet = line.extra && line.extra.outlet ? true : false
        var realStock = state.cart.matsNoStock.find(mat => {
            return mat.id === line.lineId
            // return mat.id === line.lineId && (line.product.discontinued || (line.extra && line.extra.outlet))
        })
        // console.log('valor de realstock')
        // console.log(realStock)
        // console.log(realStock ? Object.keys(realStock).length : 'VAcio')
        if (realStock && Object.keys(realStock).length > 0) {
            // console.log('no esta pasando por aqui')
            //Separamos mat id según sean o no Outlet
            if (isOutlet) {
                // console.log('ES outlet')
                productQtys[line.product.productId + 'outlet'] = productQtys[line.product.productId + 'outlet'] + line.qty || line.qty
                matStock.stockOutletAvailable = realStock.realStock
            } else {
                // console.log('NO ES OUTLET')
                productQtys[line.product.productId] = productQtys[line.product.productId] + line.qty || line.qty

                matStock.stockAvailable = realStock.realStock
            }
        }
        return matStock
    })
    // console.log(matWithoutStock)
    matWithoutStock = matWithoutStock.filter(lineExt => {
        // console.log('line ext filter')
        // console.log(lineExt)
        if (lineExt.stockAvailable != undefined || lineExt.stockOutletAvailable != undefined) return true
        return false
    })
    // console.log(matWithoutStock)
    // console.log('Valor matWithoustocko')
    // console.log(matWithoutStock)

    return matWithoutStock
}
export const getIsCartCutToSize = state => {
    //REVIEW: ¿se pueden mezclar los tipo de productos? NO
    const lines = getLines(state)
    return lines.filter(line => line.cutToSize).length > 0 && lines.filter(line => !line.cutToSize).length === 0

    // if (getCartTypes(state).every(type => ['CUT_TO_SIZE'].includes(type))) {
    //     return true
    // }
    // return false
}
export const isMallCartOnly = state => {
    if (!getCartTypes(state) || getCartTypes(state).length == 0) return false
    // console.log('tipos')
    // console.log(getCartTypes(state))
    if (getCartTypes(state).every(type => ['MALL'].includes(type))) {
        return true
    }
    return false
}
export const isMallCartForContainer = state => {
    if (isMallCartOnly(state) && getShippingMethod(state) === 'CONTAINER') {
        return true
    }
    return false
}
export const getThicknessInCart = state => {
    let thickness = []

    state.cart.lines.forEach(line => {
        //dont count if step is 1
        thickness.push(line.product.thickness)
    })
    return Array.from(new Set(thickness))
}
export const getTotalThicknessInCart = state => {
    let thickness = 0

    state.cart.lines.forEach(line => {
        let thicknessOfThisLine = line.product.thickness * line.qty
        thickness = thickness + thicknessOfThisLine
    })
    return thickness
}
export const getLenghtBundlesCart = state => {
    let numOfRealBundles = state.cart.lines.filter(line => {
        return line.bundleId !== null
    }).length
    let numOfCatalogBundles = 0
    if (getShippingMethod(state) !== 'SINGLE_SLAB') {
        state.cart.lines.forEach(line => {
            if (line.bundleId === null && line.lotId === null && line.product.minQty[getShippingMethod(state)] !== 1)
                //dont count if step is 1
                numOfCatalogBundles = numOfCatalogBundles + line.qty / line.product.minQty[getShippingMethod(state)]
        })
    }

    return numOfRealBundles + numOfCatalogBundles
}
export const getCurrent = (state, unit) => {
    return unit === 'KG' ? weightSelector(state) : Math.floor(weightSelector(state) * getKgPoundRatio())
}
export const getGoalMin = (state, unit) => {
    let goalMin = getShippingMethod(state) === 'CONTAINER' ? 19500 : 12000

    switch (getSelectedCenter(state)) {
        case getLatinaVitoriaCenterId():
        case getNaturalStoneSpainCenterId():
            if (getShippingMethod(state) === 'ENDI_TRUCK') {
                goalMin = 0
                break
            } else if (getShippingMethod(state) === 'ENDI_CONTAINER') {
                goalMin = 0
                break
            }
            if (getCartTypes(state).every(type => ['BUNDLE'].includes(type))) {
                if (getCartBrands(state).every(brand => ['SENSA', 'SCALEA'].includes(brand))) {
                    goalMin = 15000
                }
            }
            break
        // case 1000: // HQ
        //     goalMin = 12000
        //     break
        // case 7536: // HUB Sydney
        //     goalMin = 19000
        //     break
        // case 7625: // HUBs de USA

        // case 7626:
        //     goalMin = 21500
        //     break
        // case 7507: // Centro 7507 de Brasil
        //     goalMin = 17000
        //     break
        default:
            if (getShippingMethod(state) === 'CONTAINER') {
                goalMin = 19500
            } else if (getShippingMethod(state) === 'ENDI_TRUCK') {
                goalMin = 0
            } else if (getShippingMethod(state) === 'ENDI_CONTAINER') {
                goalMin = 0
            } else {
                goalMin = 12000
            }
    }
    if ((getSalesOrg(state) === '2011' || getSalesOrg(state) === '2032') && (getShippingMethod(state) === 'FULL_TRUCK' || getShippingMethod(state) === 'SINGLE_SLAB')) {
        goalMin = 19040
    }
    const goalMinAcordingUnit = unit === 'KG' ? goalMin : Math.floor(goalMin * getKgPoundRatio())
    return goalMinAcordingUnit
}
export const getGoalMax = (state, unit) => {
    let goalMax = getShippingMethod(state) === 'CONTAINER' ? 20200 : 24000

    switch (getSelectedCenter(state)) {
        case getLatinaVitoriaCenterId():
            goalMax = getShippingMethod(state) === 'CONTAINER' ? 27000 : 24000
            break
        // case 1000: // HQ
        //     goalMax = 24000
        //     break
        // case 7536: // HUB Sydney
        //     goalMax = 35000
        //     break
        // case 7625: // HUBs de USA
        // case 7626:
        //     goalMax = 23000
        //     break
        // case 7507: // Centro 7507 de Brasil
        //     goalMax = 39000
        //     break
        default:
            if (getShippingMethod(state) === 'CONTAINER') {
                goalMax = 20200
            } else if (getShippingMethod(state) === 'ENDI_TRUCK') {
                goalMax = 24000
            } else if (getShippingMethod(state) === 'ENDI_CONTAINER') {
                goalMax = 21000
            } else {
                goalMax = 24000
            }
    }
    if ((getSalesOrg(state) === '2011' || getSalesOrg(state) === '2032') && getShippingMethod(state) === 'FULL_TRUCK') {
        goalMax = 19958
    }
    const goalMaxAcordingUnit = unit === 'KG' ? goalMax : Math.floor(goalMax * getKgPoundRatio())

    return goalMaxAcordingUnit
}

/**
 * @param min
 * @param max
 * @param qty
 * @param hasMinimum
 * @returns {{isValid: boolean, units: number, qtyForNextUnit?: number}}
 */
export const getUnitsForQty = (min, max, qty, hasMinimum = true) => {
    const output = {
        units: 0,
        isValid: false,
        currentQtyWeight: qty,
        currentMinWeight: min,
        currentMaxWeight: max
    };

    const units = (qty / max) | 0;
    output.units = units;
    //If qty equals max then we're not calculating a new unit (truck/container) yet
    output.currentMinWeight = qty !== max ? (max * units) + min : (max * (units - 1)) + min
    output.currentMaxWeight = qty !== max ? (max * units) + max : (max * (units - 1)) + max

    const lastUnitQty = qty % max;

    if (lastUnitQty < min){
        output.qtyForNextUnit = min - lastUnitQty;
    } else {
        output.qtyForNextUnit = max - lastUnitQty;
    }

    if(hasMinimum && lastUnitQty > 0 && lastUnitQty < min){
        return output
    } else {
        output.isValid = true;
        output.units = lastUnitQty === 0 || lastUnitQty < min ? units : units + 1

        return output
    }
}

export const getKgPoundRatio = () => {
    return 2.20462
}
export const getQtyProductsCart = state => {
    let result = []
    state.cart.lines.forEach(line => {
        if (line.product.discontinued || (line.extra && line.extra.outlet)) {
            if (!result[line.productId]) result[line.productId] = 0
            result[line.productId] = result[line.productId] + line.qty
        }
    })
    return result
}
export const getLastLine = state => {
    return state.cart.lines[state.cart.lines.length - 1]
}
export const getLinesByBundleIds = (state, bundleids) => {
    return state.cart.lines.filter(line => bundleids.includes(line.bundleId))
}
export const getLinesByMatId = (state, productId) => {
    return state.cart.lines.filter(line => productId.includes(line.productId))
}
export const getLinePricePhase2ByLineId = (state, lineId) => {
    let price = null
    let linesPrice = getLinesPrice(state)
    if (linesPrice) {
        let priceYYYY = linesPrice[lineId] && linesPrice[lineId].price
        price = priceYYYY ? priceYYYY : 0
    }

    return price
}
export const getVoucher = state => (state.cart.vouchers ? state.cart.vouchers[0] : null)
export const getVoucherStatusText = state => state.cart.voucherStatusText
export const getIsValidVoucher = state => (state.cart.vouchers && !!state.cart.vouchers[0] ? true : state.cart.isValidVoucher)
export const getIsLoadingVoucher = state => state.cart.isLoadingVoucher
export const getShowModalNoStock = state => {
    return state.cart.showNoStockModal
}
export const phase2priceByProductId = (state, productId) => {
    let found = state.cart.phase2PricesByProduct.find(item => {
        return item.productId === productId
    })
    return found ? found.price : found
}
export const getTransformingSkuToLots = state => state.cart.transformingSkuToLots

export const getPhase2ClientByProduct = (state, productId) => {
    return state.cart.phase2FetchedPrices && state.cart.phase2FetchedPrices.get(productId)
}
export const isSuccessfullyAddedToCart = state => state.cart.successfullyAddedToCart

export const getLinesDeliveryDates = state => {
    return state.cart.linesDeliveryDates
}
export const getFormatedLinesDeliveryDates = (state) => {
    const linesSlaDate = getLinesSlaDate(state)
    const formatedLinesDates = {}

    Object.entries(state.cart.lines).forEach(([lineId, line]) => {
        const selectedLine = linesSlaDate && linesSlaDate[line.lineId]
        formatedLinesDates[line.lineId] = {daysSla: selectedLine ? selectedLine.naturalDays : 0}
    })

    Object.entries(state.cart.linesDeliveryDates).forEach(([lineId, date]) => {
        if(formatedLinesDates[lineId]){
            formatedLinesDates[lineId].date = date.format('YYYY/MM/DD')
        }
    })

    return formatedLinesDates
}
export const getDeliveryDateControlKey = state => {
    return state.cart.deliveryDateControlKey
}

export const getFinishStep = state => {
    return state.cart.finishStep
}

export const getFullUnits = state => {
    return state.cart.fullUnits
}

export const getLinesSlaDate = state => {
    return state.cart.linesSlaDate
}

export const getPreferredDeliveryDateByLines = state => {
    return state.cart.preferredDeliveryDateByLines
}

export const getIsLoadingLinesSlaDate = state => {
    return state.cart.isLoadingLinesSlaDate
}

export const getIsCheckoutPage = state => {
    return state.cart.isCheckoutPage
}

export const isDefaultDeliveryDate = state => {
    return state.cart.isDefaultDeliveryDate
}

export const hideSlaDatesForCts = state => {
    return state.cart.hideSlaDatesForCts
}

export const linesSlaDateVisibility = state => {
    return state.cart.linesSlaDateVisibility
}

export default cart
