import { getEmailUsedToLogin, getDataFromSalesforce } from '../../store/login/reducers';
import React from 'react'
import Translation from '../global/Translation'
import logo from '../../assets/img/icons/ecosentino.svg'
import CentersContainer from '../centers/CentersContainer'
import { injectIntl } from 'react-intl'

import { connect } from 'react-redux'
import LanguageSelector from '../language-selector/LanguageSelector'
import { getIsLogged, getUserName, getName, getUserType } from '../../store/login/reducers'
import AssignCenterInfoModal from '../global/AssignCenterInfoModal'

import { getIsRemembered, getAnimateChangeCenter, getCenters, getIsIkeaCenter, getIsLowesCenter, getIsEndiCenter } from '../../store/centers/reducers'
import IkeaShippingAddressContainer from '../centers/IkeaShippingAddressContainer'
import { getIsShippingAddressRemembered, getShippingAddresses, getShippingAddressFilteredToSelectByCenter, isLoadingShippingAddress } from '../../store/shipping-addresses/reducers'
import { getEmployeeId } from '../../store/big-account/reducers'
import { dispatchPushURL } from '../../store/ui/actions'
import LocalizedLink from '../global/LocalizedLink'

const mapStateToProps = state => {
    return {
        username: getUserName(state),
        name: getName(state),
        email: getEmailUsedToLogin(state),
        userType: getUserType(state),
        isLogged: getIsLogged(state),
        isRemembered: getIsRemembered(state),
        isShippingAddressRemembered: getIsShippingAddressRemembered(state),
        animateChangeCenter: getAnimateChangeCenter(state),
        centers: getCenters(state),
        shippingAddress: getShippingAddresses(state),
        isIkeaCenter: getIsIkeaCenter(state),
        employeeId: getEmployeeId(state),
        isLowesCenter: getIsLowesCenter(state),
        isEndiCenter: getIsEndiCenter(state),
        sippingAddressFilteredToSelectByCenter: getShippingAddressFilteredToSelectByCenter(state),
        isShippingAddressLoading: isLoadingShippingAddress(state),
        dataFromSalesforce: getDataFromSalesforce(state),
    }
}
const mapDispatchToProps = dispatch => {
    return {
        onSubmit: url => {
            dispatch(dispatchPushURL(url))
        },
        rememberedCenter: () => {
            dispatch({ type: 'REMEMBERED_CENTER' })
        },
        toggleShowIkeaModalEmployeeId: open => {
            dispatch({ type: 'TOGGLE_SHOW_IKEA_MODAL_EMPLOYEE_ID', open: open })
        },   push: url => {
            dispatch(dispatchPushURL(url))
        },
    }
}
class TopRibbonV2 extends React.Component {
    
    getWeURLAccessByEnvironment = () => {
        return process.env.REACT_APP_WE_ACCESS_URL
    }

    render() {
        const { username, name, email, isRemembered, isIkeaCenter, employeeId, isLowesCenter, sippingAddressFilteredToSelectByCenter } = this.props
        const showRememberSelectCenter = !isRemembered && isIkeaCenter
        const showRememberSelectShippingAddress =
            this.props.isLogged &&
            !this.props.isShippingAddressRemembered &&
            this.props.isEndiCenter &&
            this.props.sippingAddressFilteredToSelectByCenter.length !== 1 &&
            !this.props.isShippingAddressLoading

        return (
            <div id="top-ribbon-v2" className="top-ribbon-v2 d-md-block">
                {sippingAddressFilteredToSelectByCenter && (
                    <AssignCenterInfoModal
                            show={showRememberSelectCenter || showRememberSelectShippingAddress}
                            showSelectShippingAddress={showRememberSelectShippingAddress}
                            onClose={() => {
                                this.props.rememberedCenter()
                        }}
                    />
                )}
                <div className="container-fluid">
                    <div className={`row no-gutters align-items-center `} style={{ justifyContent: 'space-between' }}>
                        <div className=" d-lg-block d-none">
                            {isIkeaCenter ? (
                                <div style={{display: 'flex', columnGap: '16px'}}>
                                    {this.props.isLogged && (
                                        <span style={{fontSize:'14px'}}>
                                            {email ? email : username}
                                        </span>
                                    )}
                                    <span id="username" style={{width:'fit-content'}}>
                                        <Translation id="employee_id" defaultMessage="Employee Id" />: {employeeId}
                                    </span>
                                    <span style={{ cursor: 'pointer' }} onClick={() => this.props.toggleShowIkeaModalEmployeeId()}>
                                        <i className="fal fa-edit" />
                                    </span>
                                </div>
                            ) : isLowesCenter ? (
                                <React.Fragment>
                                    {/* <Translation id="lowes_store" defaultMessage="Lowes Store: " /> */}
                                    <span> {name}</span>
                                    {this.props.isLogged && (
                                        <span style={{fontSize:'14px'}}>
                                            {email ? email : username}
                                        </span>
                                    )}
                                </React.Fragment>
                            ) : (username &&
                                this.props.dataFromSalesforce &&
                                this.props.dataFromSalesforce.is_user_active_on_sf) ? (
                                    <span id="">
                                        <a className="go-to-cosentinowe" href={this.getWeURLAccessByEnvironment()} data-cy={'go_back'}>
                                            <span className="label">
                                                <Translation id="go_to_cosentino_we" defaultMessage="Employee Id" />{' | '}
                                            </span>
                                        </a>
                                        {this.props.isLogged && (
                                            <span style={{fontSize:'14px'}}>
                                                {email ? email : username}
                                            </span>
                                        )}
                                    </span>
                                ) : (this.props.isLogged && (
                                    <span style={{fontSize:'14px'}}>
                                        {email ? email : username}
                                    </span>
                                )
                            )}
                        </div>

                        <div id="logo" className="logo-cosentino" style={{ textAlign: 'center' }}>
                            {/* //REVIEW: ¿Es necesario con el redirect que hay en el home.js? */}
                            {this.props.center === 7777 ? (
                                this.props.userType === 'ADMIN' ? (
                                    <LocalizedLink routeId="ROUTE_CATALOG_LOWES_ADMIN" params={{ filter: 'home' }}>
                                        <img alt="eCosentino-logo" src={logo} />
                                    </LocalizedLink>
                                ) : (
                                    <LocalizedLink routeId="ROUTE_CATALOG_LOWES" params={{ filter: 'home' }}>
                                        <img alt="eCosentino-logo" src={logo} />
                                    </LocalizedLink>
                                )
                            ) : (
                                <LocalizedLink routeId="ROUTE_HOME">
                                    <img alt="eCosentino-logo" src={logo} />
                                </LocalizedLink>
                            )}
                        </div>

                        <div style={{display:'flex', columnGap: '24px', alignItems: 'center'}}>
                            <div className={`center-selector ${this.props.isIkeaCenter ? 'ikea' : ''}`}>
                                {this.props.isIkeaCenter ? <IkeaShippingAddressContainer /> : <CentersContainer />}
                            </div>
                            <div className="language-selector-topRibbon">
                                <LanguageSelector />
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        )
    }
}

export default injectIntl(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(TopRibbonV2)
)
