import { connect } from 'react-redux'
import IconButtons from './IconButtons'
import { qtySelector, showModalSelector, getIsAddingCallApi } from '../../store/cart/reducers'
import {
    getIsLogged,
    getIsAdmin,
    getUserName,
    getUserImage,
    getImpersonatedBy,
    getUserType,
    getName,
    getIsMallAdmin,
    getUserAuthorization,
    getIsCampaignsPilotUser,
    getIsMktoolsAdmin,
    canViewCrosselling,
    canViewPreorder
} from '../../store/login/reducers'
import { logout } from '../../store/login/actions'
import * as ui from '../../store/ui/actions'
import { showMenuSelector, showingProfileModal, showPersonalDataSelector, showBannerOldVersion, showingNewVersionModal } from '../../store/ui/reducers'
import { showModalSelector as showModalSelectorImpersonate, canImpersonate } from '../../store/impersonate/reducers'
import { canBuy, getSelectedCenter, getIsLowesCenter, canViewCart, getLowesCenterId, canOffer, getIsMkToolsCenter, getIsIkeaCenter } from '../../store/centers/reducers'
import { countUnassignedReservations } from '../../store/reservations/reducers'
import { getChangesRead } from '../../store/changelog/reducers'
import {withMsal} from "@azure/msal-react";
import { fetchCart } from '../../store/cart/actions'


const mapStateToProps = state => {
    return {
        username: getUserName(state),
        userType: getUserType(state),
        userimage: getUserImage(state),
        totalQty: qtySelector(state),
        countReservations: countUnassignedReservations(state),
        isLogged: getIsLogged(state),
        isAdmin: getIsAdmin(state),
        isAddingCallApi: getIsAddingCallApi(state),
        showMenu: showMenuSelector(state),
        showPersonalData: showPersonalDataSelector(state),
        showCart: showModalSelector(state),
        showProfile: showingProfileModal(state),
        showImpersonate: showModalSelectorImpersonate(state),
        impersonatedBy: getImpersonatedBy(state),
        canBuy: canBuy(state),
        canViewCart: canViewCart(state),
        changesRead: getChangesRead(state),
        selectedCenter: getSelectedCenter(state),
        isLowesCenter: getIsLowesCenter(state),
        canImpersonate: canImpersonate(state),
        canViewCrossellin: canViewCrosselling(state),
        LowesCenterId: getLowesCenterId(),
        name: getName(state),
        canRouteOfferStatusHistory: canOffer(state),
        canRouteConfiguration: true,
        isMktoolsCenter: getIsMkToolsCenter(state),
        isIkeaCenter: getIsIkeaCenter(state),
        showBanner: showBannerOldVersion(state),
        show: showingNewVersionModal(state),
        isMallAdmin: getIsMallAdmin(state),
        isUserMallAuthorized: getUserAuthorization(state),
        isCampaignAdmin: getIsCampaignsPilotUser(state),
        isMktoolsAdmin: getIsMktoolsAdmin(state),
        hasPreOrder: canViewPreorder(state),
        canRouteTransfers: getIsMkToolsCenter(state) && getUserType(state) === 'ADMIN'
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onShowUpLogin: () => {
            dispatch(ui.showLoginModal())
        },
        onShowUpCart: () => {
            dispatch(ui.showCartModal())
        },
        onShowUpImpersonate: () => {
            dispatch(ui.showImpersonateModal())
        },
        onShowUpProfile: () => {
            dispatch(ui.showProfileModal())
        },
        onToggleCart: active => {
            dispatch(ui.toggleCart(active))
        },
        onToggleMenu: active => {
            dispatch(ui.toggleMenu(active))
        },
        onTogglePersonalData: active => {
            dispatch(ui.showPersonalData(active))
        },
        onToggleProfile: active => {
            dispatch(ui.toggleProfile(active))
        },
        onToggleImpersonate: active => {
            dispatch(ui.toggleImpersonate(active))
        },
        onLogout: () => {
            dispatch(logout())
        },
        fetchCart: centerId => {
            dispatch(fetchCart(centerId))
        },
    }
}
const IconButtonsContainer = withMsal(
  connect(
    mapStateToProps,
    mapDispatchToProps
)(IconButtons))

export default IconButtonsContainer
